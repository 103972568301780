.header {

  &__container {
    background-color: #0c3069;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 20px;
    min-height: 50px;
    align-items: center;
  }

  &__section {
    margin: 5px 10px;
  }

  &__home-button {
    background: none;
  }
}
