.account-card {
    padding: 0px;
    cursor: pointer;

    &__full-size {
        @extend .account-card;
        cursor: auto;
        padding-bottom: 35px;
        border-bottom: 1px dashed green;
    }

    &__entity-control-buttons {
        float: right;
        margin: 5px;

        &__full-size {
            @extend .account-card__entity-control-buttons;
            position: relative;
            margin: 5px 5px -40px 5px;
        }

        &__rtl {
            float: left;
        }
    }

    &__container {
        background-color: white;
        padding: 10px;
        box-shadow: 0px 0px 2px 0px;
        border-radius: 2px;
        font-size: 14px;

        &__full-size {
            @extend .account-card__container;
        }

        &__line {
            display: flex;
            justify-content: space-between;

            &__full-size {
                @extend .account-card__container__line;
                justify-content: center;
            }
        }
    }

    &__shared-icon-info {
        padding: 0px;
        display: flex;
        justify-content: flex-start;

        &__full-size {
            @extend .account-card__shared-icon-info;
            display: flex;
            flex-direction: column;
        }

        &__icon {
            padding: 5px;
            background: #156577;
            color: white;
            box-shadow: 1px 1px 2px black;
            border-radius: 15px;
            font-size: 13px;
            margin: auto 0px;
            text-align: center;

            &__full-size {
                @extend .account-card__shared-icon-info__icon;
                width: 31px;
                margin: auto;
            }
        }

        &__owner {
            color: #58586f;
            margin: auto 8px;
            font-size: 16px;

            &__full-size {
                @extend .account-card__shared-icon-info__owner;
                font-size: 20px;
            }
        }
    }

    &__title {
        color: #58586f;
        font-size: 20px;
    }

    &__balance {
        font-family: revert;
        color: darkgreen;
        color: darkgreen;
        direction: ltr;
        font-size: 23px;

        &__full-size {
            @extend .account-card__balance;
            font-size: 33px;
            margin: 10px 0px;
        }

        &__amount {
            padding: 10px;
        }

        &__negative {
            color: red;
        }
    }

    &__last-activity {
        color: #58586f;
        font-style: italic;
        font-family: monospace;
        margin: 5px;

        &__full-size {
            @extend .account-card__last-activity;
        }
    }

    &__view-permissions-button {
        padding: 0px 10px;
        margin: 0 3px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__view-stats-button {
        padding: 0px 10px;
        margin: 0 3px;
        border-radius: 5px;
        cursor: pointer;
    }
}
