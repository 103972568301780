.checks-list-component {
  margin-bottom: 10px;
  padding-bottom: 50px;

  &__add-checks {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;

    &__button {
      border-radius: 20px;
      width: 150px;
      box-shadow: 0px 2px 4px;
    }
  }

  .checks-status-switch {
    margin: 5px 0;

    &.ant-switch-checked {
      background: green;
    }
    &.ant-switch-checked:hover {
      background: green;
    }
  }

  .checks-list {
    &__item {
      padding: 0px;
    }

    &__info-section {
      width: 100%;
      margin: 15px 0;
      display: flex;
      flex-flow: row-reverse wrap;
      justify-content: space-between;

      &__action-buttons {
        &__csv-export-button {

        }
      }

      &__filtered_summary {
        font-size: 20px;
        font-weight: bold;

        &__status {

        }

        &__currency {
          display: flex;
          justify-content: flex-start;
        }

        &__amount {
          direction: ltr;
          margin: auto 0px;
          width: fit-content;

          &__value {
            margin: auto 4px;
          }
        }
      }
    }
  }

  .checks-filters {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;

    &__space {
      display: flex;
      flex-flow: row wrap;
    }
  }
}
