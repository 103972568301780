.account-page {
  &__container {
    flex-direction: column;
    justify-content: center;
    padding: 1px 20px;
    background-color: white;
    height: 100%;
    min-height: 100vh;
    margin-top: 5px;
  }

  &__account-card {
  }

  &__transactions-list {
    margin-top: 30px;
    padding: 0px 20px;
  }
}
