.account-stats-modal {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
  }

  &__chart {
    width: 100%;
    padding: 0px;
    height: 250px;
    direction: ltr;
  }

  &__details-list {
    margin: 15px 0px 0px 0px;
    padding: 0px;
    direction: ltr;
  }

  &__loading {
    margin: 0px auto;
  }

  &__total-balance {
    margin: 0px auto;
    font-size: 16px;
    font-weight: bold;
  }
}
