.check-card {
    font-size: 20px;

    &__container {
        background-color: white;
        padding: 10px;
        box-shadow: 0px 0px 2px 0px;
        border-radius: 2px;
        font-size: 20px;
    }

    &__entity-control-buttons {
        justify-content: flex-end !important;
    }

    &__line {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
    }

    &__action-type {
        font-weight: bold;
        font-family: "Lucida Sans Unicode";
        text-transform: capitalize;
        margin: auto 0px;
        white-space: nowrap;
    }

    &__amount {
        font-family: "Courier New";
        font-weight: bold;
        padding: 5px;
        direction: ltr;
        margin: auto 5px;
        white-space: nowrap;

        &__value {
            margin: auto 4px;
        }
    }

    &__date-info {
        font-size: 16px;
        white-space: nowrap;
    }

    &__running_balance {
        font-size: 16px;
        margin: auto 10px;
        direction: ltr;
        white-space: nowrap;

        &__value {
            margin: auto 2px;
        }
    }

    &__description {
        font-size: 20px;
    }

    &__tags {
        margin-top: 5px;
        font-size: 14px;
    }

    &__actions {
        width: 20%;
        &__button {
            margin: 0 5px;
        }
    }
}