.account-groups-list {
  border-radius: 5px;
  margin: 5px auto;

  &__add-account-group {
    display: flex;
    justify-content: center;
    margin: 20px;

    &__button {

    }
  }

  &__go-to-checks {

    &__button {

    }
  }
}