.confetti {
  rect {
    opacity: 0;
  }

  rect:nth-child(1) {
    transform-origin: 45px 5px;
    transform: rotate(-145deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 88ms;
    animation-duration: 1030ms;
  }

  rect:nth-child(2) {
    transform-origin: 87px 5px;
    transform: rotate(164deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 131ms;
    animation-duration: 1520ms;
  }

  rect:nth-child(3) {
    transform-origin: 128px 6px;
    transform: rotate(4deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 92ms;
    animation-duration: 1298ms;
  }

  rect:nth-child(4) {
    transform-origin: 170px 6px;
    transform: rotate(-175deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 17ms;
    animation-duration: 1029ms;
  }

  rect:nth-child(5) {
    transform-origin: 213px 5px;
    transform: rotate(-97deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 122ms;
    animation-duration: 1193ms;
  }

  rect:nth-child(6) {
    transform-origin: 255px 6px;
    transform: rotate(57deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 271ms;
    animation-duration: 1020ms;
  }

  rect:nth-child(7) {
    transform-origin: 297px 5px;
    transform: rotate(-46deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 131ms;
    animation-duration: 1010ms;
  }

  rect:nth-child(8) {
    transform-origin: 338px 6px;
    transform: rotate(-65deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 85ms;
    animation-duration: 1559ms;
  }

  rect:nth-child(9) {
    transform-origin: 380px 6px;
    transform: rotate(13deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 128ms;
    animation-duration: 1429ms;
  }

  rect:nth-child(10) {
    transform-origin: 423px 5px;
    transform: rotate(176deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 311ms;
    animation-duration: 1367ms;
  }

  rect:nth-child(11) {
    transform-origin: 465px 5px;
    transform: rotate(108deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 108ms;
    animation-duration: 1199ms;
  }

  rect:nth-child(12) {
    transform-origin: 506px 6px;
    transform: rotate(62deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 105ms;
    animation-duration: 1410ms;
  }

  rect:nth-child(13) {
    transform-origin: 549px 5px;
    transform: rotate(16deg);
    animation: blast 1000ms 2 ease-out;
    animation-delay: 149ms;
    animation-duration: 1599ms;
  }

  rect:nth-child(odd) {
    fill: #4258B0;
  }

  rect:nth-child(even) {
    z-index: 1;
    fill: #EE1C71;
  }

  rect:nth-child(4n) {
    animation-duration: 1400ms;
    fill: #F8A00E;
  }

  rect:nth-child(3n) {
    animation-duration: 1750ms;
    animation-delay: 1000ms;
  }

  rect:nth-child(4n-7) {
    fill: #7A68BA;
  }

  rect:nth-child(6n) {
    fill: #66CDDF;
  }

  rect:nth-child(13) {
    fill: #7DD2B6;
  }

  rect:nth-child(9),
  rect:nth-child(13) {
    fill: #7DD2B6;
  }

  @keyframes blast {
    from {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    to {
      transform: translateY(200px);
    }
  }
}
