.transactions-list-component {
  margin-bottom: 10px;
  padding-bottom: 50px;

  &__add-transactions {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -50px;
    margin-bottom: 20px;

    &__button {
      border-radius: 20px;
      width: 150px;
      box-shadow: 0px 2px 4px;
    }
  }

  .transactions-list {
    &__item {
      padding: 0px;
    }

    &__info-section {
      width: 100%;
      margin: 15px 0;
      display: flex;
      flex-flow: row-reverse wrap;
      justify-content: space-between;

      &__action-buttons {
        &__csv-export-button {

        }
      }

      &__filtered_summary {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;

        &__amount {
          direction: ltr;
          margin: auto 4px;

          &__value {
            margin: auto 4px;
          }
        }
      }
    }
  }

  .transactions-filters {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;

    &__space {
      display: flex;
      flex-flow: row wrap;
    }
  }
}
