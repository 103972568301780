.check-form {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    padding: 10px;
  }

  &__input {
    margin: 5px;
    &__container {
      width: 100%;
    }
    &__date {
      width: 100%;
    }
    &__amount_row_space {
      width: 70%;
    }
    &__amount {
      width: 100%;
    }
    &__from_account {
      width: 100%;
    }
    &__to_account {
      width: 100%;
    }
  }

  &__button {
    &__container {
      padding-top: 10px;
      margin: 5px;
      display: flex;
      justify-content: space-between;
    }
    width: 45%;
  }
}
